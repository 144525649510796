/*
 * FOOTER
 */

.c-footer {
    width: 100%;
    overflow: hidden;
    background-color: $white;

    ul {
        padding: 0;
        margin: 0;

        li {
            list-style: none;
            display: inline-block;

            &:first-child {

                &:before {
                    content: '';
                    display: none;
                }
            }

            &:before {
                content: '\2022';
                margin-right: 4px;
            }
        }
    }

    p, a, ul, li {
        font-size: .85rem;
    }

    p {
        margin-bottom: 0;
    }

    a {
        text-decoration: none;
    }
}

.c-footer-top {}

.c-footer-bottom {
    ul {
        li, a {
            color: $medium;
            text-transform: uppercase;
            font-size: .75rem;
        }
    }
}

.c-footer-address {

}

.c-footer-openinghours {
    text-transform: uppercase;
}

.c-footer-openingshours-inner {
    display: inline-block;
    border: 1px solid $secondary;
    @include media-query(md) {
        display: block;
        border: none;
    }
}

.c-footer-social {

    p {
        display: inline-block;
    }

    .c-button-social {
        display: block;
        width: 40px;
        height: 40px;
    }
}

.c-footer-cookies {}
