/*
 * IMAGE BG
 */

.u-img-bg {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
