.v-collapse-group {
    border-bottom: 1px solid $dark;
}

.v-collapse-content{
    max-height: 0;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
}

.v-collapse-content-end{
    transition: max-height 0.3s ease-in;
    max-height: 2500px;


}

.v-collapse-header {
    cursor: pointer;
    border-top: 1px solid $dark;
    display: block;

    h3 {
        text-transform: uppercase;
        font-size: 18px !important;
        display: block;
    }

    i {
        font-weight: 100;
        display: block;
    }

    .chevron::before {
        margin-top: 5px;
        border-style: solid;
        border-width: 0.075em 0.075em 0 0;
        content: '';
        display: inline-block;
        height: 7px;
        left: 0.25em;
        position: relative;
        top: 0.25em;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 7px;
    }

    .chevron.bottom:before {
        top: 0;
        transform: rotate(135deg);
    }
}
