/*
 * SETTINGS
 */

// GRID
$columns: 12;
$gutter: 16/16*1rem;

$breakpoints: (
    xs: 480/16*1rem,
    sm: 640/16*1rem,
    md: 960/16*1rem,
    lg: 1024/16*1rem,
    xl: 1140/16*1rem
);

// SPACING
$spacing: (
    auto: auto,
    none: 0/16*1rem,
    xs: 8/16*1rem,
    sm: 16/16*1rem,
    md: 24/16*1rem,
    lg: 32/16*1rem,
    xl: 40/16*1rem,
    xxl: 80/16*1rem
);

// COLORS
$info: #000000;
$success: #4caf50;
$warning: #ffc107;
$error: #e70638;

$dark: #000000;
$medium: rgba(14,41,60,.5);
$grey: #BFBFBF;
$light: #eeeeee;        // rgba(238, 238, 238, 1)
$white: #ffffff;

$primary: #0E293C;      // rgba(14, 41, 60, 1)
$secondary: #DCB948;    // rgba(198, 3, 127, 1)
$tertiary: #F0EAD6;     // rgba(35, 125, 192, 1)

// SOCIAL MEDIA
$linkedin: #0077b5;
$facebook: #3b5998;
$twitter: #1da1f2;
$instagram: #c13584;

// BORDER RADIUS
$border-radius: 1px;

// TYPOGRAPHY

@import url("https://use.typekit.net/kkp6ggo.css");

// century-gothic

$itcAvantGardeGothicPro: 'itc-avant-garde-gothic-pro', Helvetica, sans-serif;
$centuryGothic: 'century-gothic', Times, serif;

$min: 0.85;

$base-font-family: $centuryGothic;
$base-font-size-min: 1rem * $min;
$base-font-size-max: 1rem;
$base-font-weight: normal;
$base-line-height: 1.65;
$base-color: $dark;

$h1-font-family: $itcAvantGardeGothicPro;
$h1-font-size-min: 1.25rem * $min;
$h1-font-size-max: 1.25rem; //55px
$h1-font-weight: bold;
$h1-line-height: 1.5;
$h1-color: $dark;

$h2-font-family: $itcAvantGardeGothicPro;
$h2-font-size-min: 1.25rem * $min;
$h2-font-size-max: 1.25rem; //44px
$h2-font-weight: 400;
$h2-line-height: 1.5;
$h2-color: $dark;

$h3-font-family: $centuryGothic;
$h3-font-size-min: 1rem * $min;
$h3-font-size-max: 1rem; //30px
$h3-font-weight: bold;
$h3-line-height: 1.5;
$h3-color: $dark;

$h4-font-family: $centuryGothic;
$h4-font-size-min: 1rem * $min;
$h4-font-size-max: 1rem; //26px
$h4-font-weight: 400;
$h4-line-height: 1.5;
$h4-color: $dark;

$h5-font-family: $centuryGothic;
$h5-font-size-min: 1rem * $min;
$h5-font-size-max: 1rem; //20px
$h5-font-weight: 400;
$h5-line-height: 1.5;
$h5-color: $dark;

$h6-font-family: $centuryGothic;
$h6-font-size-min: 1rem * $min;
$h6-font-size-max: 1rem; //18px
$h6-font-weight: 400;
$h6-line-height: 1.5;
$h6-color: $dark;
