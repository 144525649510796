/*
 * HEADER
 */

.c-header {
    width: 100%;
    background-color: rgba(14,41,60,.8);
    z-index: 100;
    position: fixed;
    border-bottom: 1px solid $secondary;

    a {
        width: 100%;
        color: $secondary;
        text-transform: uppercase;
        cursor: pointer;
    }

    button {
        background-color: transparent;
        border: none;

        &:focus {
            border: none;
            outline: none;
        }
    }
}

.c-header-left, .c-header-center, .c-header-right {
    flex: 1;
}
