/*
 * MAIN
 */

#main.c-main {
    border-bottom: 2px solid $secondary;
    background-color: $primary;
}

section.c-section {
    width: 100%;
    overflow: hidden;

    .o-grid, .o-col {
        overflow: hidden;
        position: relative;
    }

    h1 {
        color: $secondary;
    }

    h3 {
        color: $white;
    }

    h2, h4, h5, h6, p, a {
        color: $tertiary;
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }

    @include media-query(md) {
        min-height: 100vh;

        .o-grid, .o-col {
            min-height: 100vh;
        }
    }
}

section.c-section.c-section-intro {
    padding: 120px 0 50px;
    position: relative;

    .u-text-container {
        max-width: 350px;
    }

    @include media-query(md) {
        min-height: 0;
    }
}

body.reservations {

    section.c-section.c-section-intro {

        .u-text-container {
            max-width: 700px;
        }
    }
}

section.c-section.c-section-intro.c-section-intro-small {
    padding: 100px 0 0 0;
    min-height: 0;
}

.c-section-intro-inner {
    height: 100%;
    position: relative;
}

nav.c-menu {

    a.c-menu-item {
        font-weight: 100;
        text-transform: uppercase;
        color: $secondary;
        border: 1px solid $secondary;
        display: inline-block;
        padding: 0 8px;

        &:hover {
            text-decoration: none !important;
        }
    }
}

.c-section-content {
    width: 100%;
    max-width: 350px;

    @include media-query(md) {
        max-width: 350px;
    }
    position: relative;
}

.c-section-content-boxed {
    border: 1px solid $secondary;
}

.c-section-content-boxed-inner-menu {
    position: relative;
    padding-bottom: 24px;
    h3 {
        font-family: $itcAvantGardeGothicPro;
        font-weight: 400;
        text-transform: uppercase;
    }
    ul {
        padding: 0;
        margin: 0;

        li {
            list-style: none;
            display: inline-block;
            color: $tertiary;

            &:first-child {

                &:before {
                    content: '';
                    display: none;
                    font-size: .5rem;
                }
            }

            &:before {
                content: '\2022';
                margin-right: 4px;
            }
        }
    }
    &:after {
        position: absolute;
        content: '';
        width: 4px;
        height: 4px;
        background-color: $white;
        display: block;
        left: 50%;
        margin-left: -2px;
        bottom: 10px;
        transform: rotate(45deg);
    }
    &:last-child {
        &:after {
            display: none;
        }
    }
}

.c-section-content-boxed-title {
    position: absolute;
    width: 100%;
    top: -14px;

    h2 {
        display: inline-block;
        margin: 0;
        background-color: $primary;
        padding: 0 8px;
    }
}

.c-section-content-boxed-inner {
    padding: 16px 8px 32px 8px;
}

.c-section-content-boxed-btn {
    position: absolute;
    width: 100%;
    bottom: -20px;
}

.c-divider {
    width: 50px;

    .c-divider-left {
        display: block;
        height: 1px;
        width: 20px;
        background-color: $secondary;
    }

    .c-divider-center {
        display: block;
        width: 4px;
        height: 4px;
        background-color: $secondary;
        transform: rotate(45deg);
    }

    .c-divider-right {
        display: block;
        height: 1px;
        width: 20px;
        background-color: $secondary;
    }
}

// Homepage

body.home, body.maintenance {

    section.c-section-intro {
        min-height: 100vh;
        background-size: cover;
        background-position: center center;

        .o-grid, .o-col {
            min-height: 100vh;
        }
    }
}

aside.sidebar {
    background-color: $secondary !important;

    img {
        width: 150px;
    }

    width: 200px !important;

    @include media-query(xs) {
        width: 320px !important;
    }
}

.c-drawer-content {
    min-height: 100vh;
}

.c-drawer-content-nav {

    display: block;
    position: relative;

    &:before {
        position: absolute;
        bottom: 26px;
        left: 50%;
        margin-left: -2px;
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        background-color: $primary;
        transform: rotate(45deg);
    }
}

.c-drawer-content-nav-item {
    position: relative;

    &:before {
        position: absolute;
        top: -8px;
        left: 50%;
        margin-left: -2px;
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        background-color: $primary;
        transform: rotate(45deg);
    }

    a {
        text-transform: uppercase;
        display: inline-block;
        position: relative;

        &:after {
            position: absolute;
            display: inline-block;
            background-color: $primary;
            height: 1px;
            width: 100%;
            content: '';
            opacity: 0;
            transition: opacity 300ms ;
            bottom: 0;
            left: 0;
        }

        &:hover {

            &:after {
                opacity: 1;
            }
        }
    }
}

.c-drawer-content-nav-item-active {

    a {
        &:after {
            opacity: 1;
        }
    }
}

.c-drawer-content-nav {}

.c-drawer-content-nav-lang-item {

    position: relative;

    &:before {
        position: absolute;
        top: 50%;
        left: -10px;
        margin-top: -2px;
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        background-color: $primary;
        transform: rotate(45deg);
    }

    &:first-child {
        &:before {
            display: none;
        }
    }

    a {
        text-transform: uppercase;
        display: inline-block;
        position: relative;

        &:after {
            position: absolute;
            display: inline-block;
            background-color: $primary;
            height: 1px;
            width: 100%;
            content: '';
            opacity: 0;
            transition: opacity 300ms;
            bottom: 0;
            left: 0;
        }

        &:hover {

            &:after {
                opacity: 1;
            }
        }
    }
}

.c-drawer-content-nav-item-lang-active {

    a {
        &:after {
            opacity: 1;
        }
    }
}

[v-cloak] {
    display: none;
}
