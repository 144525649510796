/*
 * ICONS
 */

.c-icon {
    display: inline-block;
}

.c-icon-reservation {
    width: 17px;
    height: 25px;
}

.c-icon-menu {
    width: 32px;
    height: 20px;
}

.c-icon-btn_down {
    width: 40px;
    height: 20px;
    display: block;
}

.c-icon-btn_social_facebook, .c-icon-btn_social_instagram {
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 auto;
}
