.c-codesnippet{
  margin: 30px 0 30px 0;

  .c-codesnippet-wrap{
    position:relative;
    button{
      position:absolute;
      right:0;
      top:0;
      font-size:0.8rem;
    }
  }
}

// PRISM
