.c-sidebar{
  background-color: $dark;
  color: $white;
  position:fixed;
  left:0;
  transition: all 0.3s ease-out;

  .is-pushed-right &{
    left:-320px;
  }

  top:0;
  height:100vh;
  overflow-y:scroll;
  z-index:101;

  a{
    &.active{
      font-weight:bold;
      color: $primary;
      transition: all 0.3s ease-out;
    }
  }
}