/*
* FORMS
*/

.c-form-group {

}

.c-form-input {

}

.c-label {
    font-weight: 700;
    font-size: 1.125rem;
    color: $medium;
}

.c-label-floating {
    left: 20px;
    margin-bottom: 0;
    padding: 0 13px;
    position: relative;
    top: 10px;
    background: $white;
    display: inline-block;
}

.c-form-input-text,
.c-form-input-password,
.c-form-input-textarea {
    background-color: $white;
    border: 1px solid $medium;
    padding: 1.285rem 0.75rem;
    line-height: 1.5;
    font-size: 1.125rem;

    &:focus {
        border-color: $primary;
    }
}

.c-form-icon-radio {
    width: 26px;
    height: 26px;
    margin-right: 8px;
    background-color: $white;
    border: 1px solid $medium;

    &:after {
        background-color: $primary;
        margin-top: -7px;
        margin-left: -7px;
        width: 14px;
        height: 14px;
    }
}

.c-form-icon-checkbox {
    height: 26px;
    width: 26px;
    margin-right: 8px;
    background-color: $white;
    border: 1px solid $medium;

    &:after {
        fill: $primary;
    }
}

// Errors

.c-form-error {
    p {
        color: $error;
    }
}

.c-form-description {
    p {
        font-size: .75em;
    }
}

.c-form-group.hasError {
    .c-label {
        color: $error;
    }
    .c-form-input {
        border: 1px solid $error;
    }
}
