/*
 * PARAGRAPHS
 */

p {

    a {
        color: $primary;
        text-decoration: underline;
        transition: color 300ms ease;
        cursor: pointer;

        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }

    small {

        a {
            font-size: 1em;
        }
    }

    cite {

        font-style: italic;

        &::before, &::after {
            display: inline-block;
            vertical-align: top;
            height: 30px;
            line-height: 48px;
            font-size: 45px;
            opacity: .75;
        }

        &:before {
            content: '\201C';
            margin-right: 8px;
            margin-top: -8px;
        }

        &:after {
            content: '\201D';
            margin-top: 8px;
        }
    }
}

blockquote {

    margin: 0 !important;

    p {

        font-style: italic;

        &::before, &::after {
            display: inline-block;
            vertical-align: top;
            height: 30px;
            line-height: 48px;
            font-size: 45px;
            opacity: .75;
        }

        &:before {
            content: '\201C';
            margin-right: 8px;
            margin-top: -8px;
        }

        &:after {
            content: '\201D';
            margin-top: 8px;
        }
    }
}
