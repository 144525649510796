/*
 * BUTTONS
 */

.c-button {
    display: inline-block;
    padding: .625em 1.125em;
    background-color: $primary;
    border-radius: $border-radius;
    transition: all .1s ease;
    font-weight: 400;
    color: $secondary !important;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid $secondary;
    font-size: .9rem;

    &:hover {
        //background-color: lighten($light, 5%);
        text-decoration: none !important;
        color: $secondary !important;
    }
}

.c-button-info {
    background-color: $info;
    color: $white;

    &:hover {
        background-color: lighten($info, 5%);
        color: $white;
    }
}

.c-button-success {
    background-color: $success;
    color: #fff;

    &:hover {
        background-color: lighten($success, 5%);
        color: $white;
    }
}

.c-button-warning {
    background-color: $warning;
    color: $white;

    &:hover {
        background-color: lighten($warning, 5%);
        color: $white;
    }
}

.c-button-error {
    background-color: $error;
    color: $white;

    &:hover {
        background-color: lighten($error, 5%);
        color: $white;
    }
}

.c-button-primary {
    background-color: $primary;
    color: $white;

    &:hover {
        background-color: lighten($primary, 5%);
        color: $white;
    }
}

.c-button-secondary {
    background-color: $secondary;
    color: $white;

    &:hover {
        background-color: lighten($secondary, 5%);
        color: $white;
    }
}

.c-button-tertiary {
    background-color: $tertiary;
    color: $white;

    &:hover {
        background-color: lighten($tertiary, 5%);
        color: $white;
    }
}

// Links

.c-link-chevron {
    font-weight: 700;
}

.c-link-chevron::after {
    border-style: solid;
    border-width: 0.085em 0.085em 0 0;
    content: '';
    display: inline-block;
    height: .5em;
    left: .5em;
    position: relative;
    top: .6em;
    transform: rotate(45deg);
    transition: all .15s ease;
    vertical-align: top;
    width: .5em;
}

// To: Top, Down

.c-button-top {

}

.c-button-down {

    .c-icon {
        transform: rotate(180deg);
    }
}
